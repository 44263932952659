import AirDatepicker from 'air-datepicker';
import Inputmask from 'inputmask';
import 'air-datepicker/air-datepicker.css';
import { dropdownDown, dropdownToggle, convertsDateToIsoFormat } from '../../../utils/utils';
import Cell from '../Cell';
import './multiDate.scss';

export default class MultiDate extends Cell {
    constructor($cell, options) {
        super($cell);
        this.$inputs = this.$cell.querySelectorAll('.js-multi-date-input');
        this.$startValue = this.$cell.querySelector('.js-cell-current-value');
        this.multiDateError = false;
        this.startDateFrom;
        this.startDateTo;
        this.inputClassCollection = { datepicker: [], inputmask: [] };
        this.onChange = options?.onChange;

        this.init();
    }

    init() {
        this.upLabel();
        this.$inputs.forEach((input) => {
            this.inputClassCollection.datepicker.push(
                new AirDatepicker(input, { onSelect: this.changesInput.bind(this), startDate: convertsDateToIsoFormat(input.value) })
            );
            this.inputClassCollection.inputmask.push(new Inputmask('99.99.9999').mask(input));
            input.addEventListener('input', this.changesInput.bind(this));
        });
        this.startDateFrom = this.$inputs[0].value;
        this.startDateTo = this.$inputs[1].value;
        !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
    }

    changesInput() {
        !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
        this.removeErrorStatus();
    }

    isInputError(showError = false) {
        const isError = this.isRequired ? this.multiDateError : this.isRequired;

        const regexp = /\d{2}(.|\/)\d{2}\1\d{4}/;
        for (let i = 0; i < this.$inputs.length; i++) {
            const input = this.$inputs[i];

            if (!regexp.test(input.value)) {
                this.multiDateError = true;
                break;
            } else {
                this.multiDateError = false;
            }
        }

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return this.multiDateError;
    }

    showField(animation = true) {
        this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
        this.isVisible = true;
        animation ? dropdownDown(this.$cell) : this.$cell.classList.remove('js-cell-hidden');
        this.upLabel();
    }

    toggleVisibility(animation = true) {
        this.isVisible = !this.isVisible;
        animation ? dropdownToggle(this.$cell) : this.$cell.classList.toggle('js-cell-hidden');
        if (!this.$cell.isVisible) {
            this.isRequired = false;
        } else {
            this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
        }
        this.upLabel();
    }

    isInputChanged() {
        // return !(this.startDateFrom === this.$inputs[0].value && this.startDateTo === this.$inputs[1].value);
        return !(this.startDateTo === this.$inputs[1].value);
    }

    disabledCalendar(calendarNumber) {
        this.$inputs[calendarNumber].setAttribute('disabled', true);
    }

    addStartValue(value) {
        if (this.$startValue) {
            this.startDateFrom = value[0];
            this.startDateTo = value[1];
            this.$inputs[0].value = value[0];
            this.$inputs[1].value = value[1];
            this.$startValue.textContent = 'C ' + this.startDateFrom + ' по ' + this.startDateTo;
        }
    }
}
