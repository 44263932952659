import { isEmailError, isPasswordError, isTextError } from '../../../utils/validation';
import { dropdownDown, dropdownToggle, dropdownUp } from '../../../utils/utils';
// import { debounce } from '../../../utils/utils';
import Cell from '../Cell';
import './cellInput.scss';

export default class CellInput extends Cell {
    constructor($cell, options) {
        super($cell);
        this.$input = this.$cell.querySelector('.js-cell-input');
        this.inputType = this.$input.getAttribute('data-input-type');
        this.$startValue = this.$cell.querySelector('.js-cell-current-value');
        this.startValue = this.$input.value;
        this.currentValue = this.startValue;
        this.onChange = options?.onChange;

        this.init();
    }

    init() {
        this.startCheck();
        !this.isRequired ? this.$cell.classList.add('js-no-required') : this.$cell.classList.remove('js-no-required');
        this.$input.addEventListener('input', this.changesInput.bind(this));
        this.$input.addEventListener('focus', this.focusInput.bind(this));
        this.$input.addEventListener('blur', this.blurInput.bind(this));
    }

    changesInput() {
        !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
        this.removeErrorStatus();
        this.onChange ? this.onChange() : null;
        this.currentValue = this.$input.value;
    }

    startCheck() {
        !this.isInputError() ? this.successfulStatus() : this.removeSuccessfulStatus();
        this.$input.value.length > 0 ? this.upLabel() : this.downLabel();
    }

    focusInput() {
        this.upLabel();
    }

    blurInput() {
        if (this.$input.value.length === 0) {
            this.downLabel();
        }
    }

    isInputError(showError = false) {
        const getStatus = () => {
            switch (this.inputType) {
                case 'email':
                    return isEmailError(this.$input);
                case 'password':
                    return isPasswordError(this.$input);
                default:
                    return isTextError(this.$input);
            }
        };
        const isError = this.isRequired ? getStatus() : this.isRequired;

        if (showError && isError && this.isRequired) {
            this.errorStatus();
        }

        return isError;
    }

    showField(animation = true) {
        this.isRequired = this.$cell.getAttribute('data-required') !== 'false';
        this.isRequired && this.$cell.classList.remove('js-no-required');
        this.isVisible = true;
        animation && dropdownDown(this.$cell);
        this.$cell.classList.remove('js-cell-hidden');
        this.$input.value.length > 0 ? this.upLabel() : this.downLabel();
    }

    hideField(animation = true) {
        this.isRequired = false;
        this.isVisible = false;
        animation && dropdownUp(this.$cell);
        this.$cell.classList.add('js-cell-hidden');
    }

    toggleVisibility(animation = true) {
        this.isVisible = !this.isVisible;
        animation && dropdownToggle(this.$cell);
        this.$cell.classList.toggle('js-cell-hidden');

        if (!this.isVisible) {
            this.isRequired = false;
        } else {
            this.isRequired = this.$cell.getAttribute('data-required') !== 'false';

            if (this.isRequired) {
                this.$cell.classList.remove('js-no-required');
                this.$input.value.length === 0 && this.$cell.classList.remove('js-label-successful');
            }
        }
        this.$input.value.length > 0 ? this.upLabel() : this.downLabel();
    }

    isInputChanged() {
        return !(this.startValue === this.currentValue);
    }

    addStartValue(value) {
        if (this.$startValue) {
            this.startValue = value;
            this.currentValue = value;
            this.$startValue.textContent = value;
        }
    }

    addInputValue(value) {
        this.startValue = value;
        this.currentValue = value;
        this.$input.value = value;
        this.startCheck();

        if (this.$startValue) {
            this.$startValue.textContent = value;
        }
    }

    changeValue(value) {
        this.currentValue = value;
        this.$input.value = value;
        this.startCheck();
    }
}
