import { dropdownDown, dropdownUp } from '../../../utils/utils';
import './loader.scss';

export default class Loader {
    constructor(cell, options) {
        this.defaultConfig = {};
        this.$cell = cell;
        this.$loader = this.$cell.querySelector('.js-loader');
        this.$input = this.$loader.querySelector('.js-loader-input');
        this.$filesList = this.$loader.querySelector('.js-loader-files-list');
        this.$addFilesButton = this.$loader.querySelector('.js-loader-add-file');
        this.$errorMessage = this.$loader.querySelector('.js-error-message');
        this.clickInput = this.clickInput.bind(this);
        this.checkEvent = this.checkEvent.bind(this);
        this.isRequired = this.$input.getAttribute('data-required') !== 'false';
        Object.assign(this.defaultConfig, options);
        this.rightExtensions = options?.rightExtensions ? options.rightExtensions : ['pdf', 'jpeg', 'jpg', 'png'];
        this.maxSize = options?.maxSize ? options.maxSize : 5;
        this.addsFilesAction = options?.addsFiles;
        this.currentValue = [];
        this.init();
    }

    init() {
        this.$loader.addEventListener('click', this.clickInput);
        this.$addFilesButton.addEventListener('click', this.clickInput);
        this.$input.addEventListener('change', this.changeFiles.bind(this));
    }

    clickInput() {
        //Удаляем обработчик клика по всему loader. Важно удалить перед вызвом клика по this.$input
        this.$loader.removeEventListener('click', this.clickInput);

        //Вызываем клик по инпуту с типом файл
        this.$input.click();

        //Вешаем на body событие фокуса, что бы поймать момент, когда пользователь нажал на кнопку отмена и не выбрал файлов
        document.body.addEventListener('focusin', this.checkEvent);

        //Кода показалось окно с выбором файлов нужно убрать фокус с body что бы после нажатия отмена, поймать событие фокуса на body
        setTimeout(() => {
            this.$input.focus();
        }, 100);
    }

    //При выборе файлов, добавит их в массив файлов
    changeFiles(e) {
        const status = this.checkFiles(Array.from(e.target.files));

        if (status.error) {
            this.errorStatus(status.errorMessage);
        } else {
            this.currentValue = this.currentValue.length > 0 ? this.currentValue.concat(Array.from(e.target.files)) : Array.from(e.target.files);
            this.addsFilesToList();
            this.addsFilesAction ? this.addsFilesAction() : null;
        }
    }

    //Удаление отображения файлов из dom
    removeFile(currentFileName) {
        this.currentValue = this.currentValue.filter((file) => file.name !== currentFileName);
        this.addsFilesToList();
        this.checkEvent();
    }

    //Добавление отображения файлов в dom
    addsFilesToList() {
        this.$filesList.innerHTML = '';
        this.removeErrorStatus();

        if (this.currentValue.length > 0) {
            this.currentValue.forEach((file) => {
                this.$filesList.insertAdjacentHTML(
                    'beforeend',
                    `
						<button class="loader__button loader__button--file js-loader-remove-file" type="button">
							<div class="loader__button-flex">
								<div class="loader__button-text js-loader-button-text">${file.name}</div>
								<svg class="loader__button-icon" width="20" height="20">
									<use href="/assets/sprite.svg#icon-loader-close"></use>
								</svg>
							</div>
						</button>
					`
                );
            });

            const $filesButton = this.$loader.querySelectorAll('.js-loader-remove-file');

            $filesButton.forEach((fileButton) => {
                fileButton.addEventListener('click', (evt) => {
                    evt.stopPropagation();
                    const targetName = fileButton.querySelector('.js-loader-button-text').textContent;
                    this.removeFile(targetName);
                });
            });
            this.$addFilesButton.style.display = 'block';
        } else {
            this.$addFilesButton.style.display = 'none';
        }

        //Удаляем слушатель фокуса на body
        document.body.removeEventListener('focusin', this.addsFilesToList);

        this.isInputError() ? this.$loader.classList.remove('js-loader-successful') : this.$loader.classList.add('js-loader-successful');
    }

    isInputError(showError = false) {
        const isError = this.isRequired ? this.currentValue.length === 0 : this.isRequired;

        if (showError && isError) {
            this.errorStatus('Добавьте файл');
        }

        return isError;
    }

    //Событие фокуса на body срабатывает раньше событие change.
    //Поэтому вызываем таймаут и проверяем необходимость добавлять обработчик клика на загрузчик
    checkEvent() {
        setTimeout(() => {
            this.currentValue.length === 0 && this.$loader.addEventListener('click', this.clickInput);
            document.body.removeEventListener('focusin', this.checkEvent);
        }, 100);
    }

    //Проверка файлов на заданные параметры
    checkFiles(files) {
        let error;
        let errorMessage = '';

        files.forEach((file) => {
            const fileSize = file.size;
            const fileExtension = file.name.split('.').pop();

            if (fileSize > this.maxSize * 1000000) {
                error = true;
                errorMessage = `Размер файлов должен быть не больше ${this.maxSize} Мб`;

                return;
            }

            if (!this.rightExtensions.includes(fileExtension)) {
                error = true;
                errorMessage = `Файл должен быть формата ${this.rightExtensions.join(', ')}`;

                return;
            }
        });

        return {
            error: error,
            errorMessage: errorMessage,
        };
    }

    errorStatus(errorMessage) {
        this.$loader.classList.add('js-loader-error');

        if (errorMessage) {
            this.$errorMessage.innerHTML = errorMessage;
        }
        dropdownDown(this.$errorMessage);
    }

    removeErrorStatus() {
        this.$loader.classList.remove('js-loader-error');
        dropdownUp(this.$errorMessage);
    }

    isInputChanged() {
        return this.currentValue.length > 0;
    }
}
