export default class Api {
    //Get запрос
    getRequest = async (url, method = 'GET') => {
        const res = await fetch(url, {
            method: method,
        });
        return res.json();
    };

    //Post запрос
    sendFormData = async (url, body) => {
        const res = await fetch(url, {
            method: 'POST',
            body: body,
        });
        return res.json();
    };

    getFiasAddressOneString = async (value) => {
        return await this.getRequest(
            `https://data.pbprog.ru/api/address/full-address/parse?token=da2ae6e7b242425e832c759ba800deb04f219607&addressText=${value}&resultLimit=10&version=0&hierarchyMode=adm`
        );
    };

    getQrCode = async (formData) => {
        return await this.sendFormData(`/Payment/GetQrcode`, formData);
    };

    getPaymentCard = async (formData) => {
        return await this.sendFormData(`/Payment/GetPaymentCard`, formData);
    };

    getProcessFinancialDefaultData = async (isn) => {
        return await this.sendFormData(`/Process/Financial/GetAgreementInfo`, isn);
    };

    getProcessPersonalDefaultData = async (isn) => {
        return await this.sendFormData(`/Process/Personal/GetAgreementInfo`, isn);
    };

    getProcessBeneficiaryDefaultData = async (isn) => {
        return await this.sendFormData(`/Process/Beneficiary/GetAgreementInfo`, isn);
    };

    getProcessSurvivalDefaultData = async (isn) => {
        return await this.sendFormData(`/Process/Survival/GetAgreementInfo`, isn);
    };

    getProcessLossDefaultData = async (isn) => {
        return await this.sendFormData(`/Process/Loss/GetAgreementInfo`, isn);
    };

    sendProcessFinancialValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Financial/Validate`, changesData);
    };

    sendProcessPersonalValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Personal/Validate`, changesData);
    };

    sendProcessBeneficiaryValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Beneficiary/Validate`, changesData);
    };

    sendProcessLossValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Loss/Validate`, changesData);
    };

    sendProcessSurvivalValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Survival/Validate`, changesData);
    };

    sendProcessTerminationValidate = async (changesData) => {
        return await this.sendFormData(`/Process/Termination/Validate`, changesData);
    };

    resendCode = async () => {
        return await this.getRequest(`/Identity/ResendCode`);
    };

    getProcessFinancialSendCode = async () => {
        return await this.getRequest(`/Process/Financial/SendCode`, 'POST');
    };

    sendProcessFinancialCheckCode = async (code) => {
        return await this.sendFormData(`/Process/Financial/CheckCode`, code);
    };

    getProcessPersonalSendCode = async () => {
        return await this.getRequest(`/Process/Personal/SendCode`, 'POST');
    };

    sendProcessPersonalCheckCode = async (code) => {
        return await this.sendFormData(`/Process/Personal/CheckCode`, code);
    };

    getProcessBeneficiarySendCode = async () => {
        return await this.getRequest(`/Process/Beneficiary/SendCode`, 'POST');
    };

    sendProcessBeneficiaryCheckCode = async (code) => {
        return await this.sendFormData(`/Process/Beneficiary/CheckCode`, code);
    };

    getProcessLossSendCode = async () => {
        return await this.getRequest(`/Process/Loss/SendCode`, 'POST');
    };

    sendProcessLossCheckCode = async (code) => {
        return await this.sendFormData(`/Process/Loss/CheckCode`, code);
    };

    getProcessSurvivalSendCode = async () => {
        return await this.getRequest(`/Process/Survival/SendCode`, 'POST');
    };

    sendProcessSurvivalCheckCode = async (code) => {
        return await this.sendFormData(`/Process/Survival/CheckCode`, code);
    };

    getProcessTerminationSendCode = async () => {
        return await this.getRequest(`/Process/Termination/SendCode`, 'POST');
    };

    sendProcessTerminationCheckCode = async (code) => {
        return await this.sendFormData(`/Process/Termination/CheckCode`, code);
    };
}
